<template>
  <DashboardTemplate>
    <div class="pd-x-5 pd-y-3" v-loading.fullscreen.lock="loading">
      <!-- <pre>{{ detail }}</pre> -->
      <el-row :gutter="20" type="flex" justify="center">
        <el-col :span="24" :md="18" :lg="12" :offset="0">
          <formPerson
            :ruleForm="detail"
            :disable="false"
            :validate="false"
            @saveData="editUser"
          />
        </el-col>
      </el-row>
    </div>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardAdminTemplate";
import formPerson from "@/views/UserInfomation";
import { HTTP } from "@/service/axios";
export default {
  components: {
    DashboardTemplate,
    formPerson
  },
  mounted() {
    this.getUserDetail();
  },
  data() {
    return {
      detail: null,
      loading: false
    };
  },
  methods: {
    editUser(data) {
      this.loading = true;
      let obj = {
        userId: data.id,
        titleName: data.titleName,
        name: data.name,
        surname: data.surname,
        gender: data.gender,
        age: data.age,
        email: data.email,
        education: data.education,
        emailEdit: data.emailEdit,
        mobilephone: data.mobilephone,
        mobilephoneEdit: data.mobilephoneEdit
      };
      HTTP.put(`system/admin/user/information`, obj)
        .then(result => {
          if (result.data.success) {
            this.$message({
              message: "บันทึกข้อมูลสำเร็จ",
              type: "success"
            });
          } else {
            this.alertCatchError(result.data.data);
          }
        })
        .catch(err => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getUserDetail() {
      this.loading = true;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.get(
        `system/admin/user/${this.$route.params.id}`
      ).catch(e => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      if (res.data.success) {
        this.detail = res.data.obj;
      }
      this.loading = false;
    }
  }
};
</script>